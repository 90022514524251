import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import './styles/main.scss';
import Swiper from 'swiper';
import CookiesEuBanner from 'cookies-eu-banner';
import _ from 'lodash';
import $ from 'jquery';

var jQuery = require("jquery");
window.$ = window.jQuery = jQuery;

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';

import 'popper.js';
require('bootstrap-sass/assets/javascripts/bootstrap');
import bsCustomFileInput from 'bs-custom-file-input'
//import './js/photoswipe.js';

import Countable from 'countable';
import morph from '@alpinejs/morph';
import Alpine from 'alpinejs';

window.Alpine = Alpine
Alpine.plugin(morph);


($ => {
  'use strict';

  var setHeroHeight = () => {
    var logo = $('.page-header').outerHeight();
    $('.hero.home').css('min-height', $(window).height() - logo - 60);
  };

  $(document).ready(setHeroHeight);
  $(window).resize(setHeroHeight);
})(jQuery);

(function($) {
  'use strict';
  var collapseOffset = 75;
  var collapseClass = 'top-nav-collapse';
  var fixedClass = 'navbar-fixed-top';

  var scrollLinks = $('.page-scroll');
  var head = $('.header');
  var navbar = $('.navbar-reiss');

  var headHeight;
  var headOuterHeight;
  var navbarHeight;

  var collapsedState = false;
  var fixedState = false;

  var collapseTimeout;

  /**
   * init heights
   */
  function init() {
    headHeight = head.height();
    headOuterHeight = head.outerHeight();
    navbarHeight = navbar.outerHeight();
  }

  /**
   * collapse bar if state is true
   * @param  {boolean} state collapse state
   */
  function collapse(state) {
    if (state) {
      navbar.addClass(collapseClass);
    } else {
      navbar.removeClass(collapseClass);
    }
    if (collapseTimeout) {
      clearTimeout(collapseTimeout);
    }
    collapseTimeout = setTimeout(function() {
      navbarHeight = navbar.outerHeight();
    }, 333);
  }

  /**
   * set bar to be fixed
   * @param  {boolean} state fixate state
   */
  function fixate(state) {
    if (state) {
      navbar.addClass(fixedClass);
      navbar.next().css('margin-top', navbarHeight);
    } else {
      navbar.removeClass(fixedClass);
      navbar.next().css('margin-top', 0);
    }
  }

  /**
   * helper function, call if value changes
   * @param  {mixed}   oldval old
   * @param  {mixed}   newval new
   * @param  {function} fn     callback
   * @return {mixed}          new value
   */
  function callOnChange(oldval, newval, fn) {
    if (oldval !== newval) {
      fn(newval);
    }
    return newval;
  }

  /**
   * scroll listener
   */
  function scrollTrack() {
    var scrollTop = $(window).scrollTop();
    collapsedState = callOnChange(
      collapsedState,
      scrollTop > (headHeight - collapseOffset),
      collapse);
    fixedState = callOnChange(fixedState, scrollTop > headOuterHeight, fixate);
  }

  // jQuery for page scrolling feature - requires jQuery Easing plugin
  scrollLinks.bind('click', function(event) {
    var link = $(this);
    var navHeight = fixedState ? navbarHeight : navbar.outerHeight();
    var pos = $(link.attr('href')).offset().top - navHeight;
    link.blur();
    $('html, body').stop().animate({
      scrollTop: pos
    }, 1000, 'easeInOutExpo');
    event.preventDefault();
  });

  // Closes the Responsive Menu on Menu Item Click
  $('.navbar-collapse ul li a').click(function() {
    $('.navbar-toggle:visible').click();
  });

  init();
  $(window).scroll(scrollTrack);
  $(window).resize(init);
  scrollTrack();
})(jQuery);


function init() {
  'use strict';
  // Basic options for a simple Google Map
  // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
  var mapOptions = {
    // How zoomed in you want the map to start at (always required)
    zoom: 15,

    // The latitude and longitude to center the map (always required)
    center: new google.maps.LatLng(
      50.835804, (Modernizr.touch ? 12.910207 : 12.910207)),

    // Disables the default Google Maps UI components
    disableDefaultUI: true,
    scrollwheel: false,
    draggable: !Modernizr.touch,

    // How you would like to style the map.
    // This is where you would paste any style found on Snazzy Maps.
    styles: [{'featureType':'landscape','stylers':[{'saturation':-100},{'lightness':65},{'visibility':'on'}]},{'featureType':'poi','stylers':[{'saturation':-100},{'lightness':51},{'visibility':'simplified'}]},{'featureType':'road.highway','stylers':[{'saturation':-100},{'visibility':'simplified'}]},{'featureType':'road.arterial','stylers':[{'saturation':-100},{'lightness':30},{'visibility':'on'}]},{'featureType':'road.local','stylers':[{'saturation':-100},{'lightness':40},{'visibility':'on'}]},{'featureType':'transit','stylers':[{'saturation':-100},{'visibility':'simplified'}]},{'featureType':'administrative.province','stylers':[{'visibility':'off'}]},{'featureType':'water','elementType':'labels','stylers':[{'visibility':'on'},{'lightness':-25},{'saturation':-100}]},{'featureType':'water','elementType':'geometry','stylers':[{'hue':'#ffff00'},{'lightness':-25},{'saturation':-97}]}]

  };

  // Get the HTML DOM element that will contain your map
  // We are using a div with id='map' seen below in the <body>
  var map = new google.maps.Map(mapElement, mapOptions);

  // Custom Map Marker Icon - Customize the map-marker.png file to customize your icon
  // var myLatLng = new google.maps.LatLng(50.810250, 12.977236);
  var myLatLng = new google.maps.LatLng(50.835804, 12.910207);
  var beachMarker = new google.maps.Marker({
    position: myLatLng,
    map: map,
    title: 'reiss.pm',
    icon: '/static/images/map-marker.png'
  });
  if (beachMarker) {
    google.maps.event.addListener(beachMarker, 'click', function() {
      window.location.href = 'https://www.google.de/maps/place/Ka%C3%9Fbergstra%C3%9Fe+28,+09112+Chemnitz/';
    });
  }

  google.maps.event.addDomListener(window, 'resize', function() {
    google.maps.event.trigger(map, 'resize');
  });
}

var mapElement = document.getElementById('map');
/*eslint-enable */
